<template>
  <ShoppingBagItemV1 
    v-if="!isEditShoppingbag" 
    :index="index"
    :item="item"
    :isOutStockGood="isOutStockGood"
    :cartsCount="cartsCount"
    :fromType="fromType"
    :isSensitiveGood="isSensitiveGood"
    :is-shein-club-gift="isSheinClubGiftItem"
  />
  <ShoppingBagItemV2 
    v-else 
    :index="index"
    :item="item"
    :isOutStockGood="isOutStockGood"
    :fromType="fromType"
    :isSensitiveGood="isSensitiveGood"
    :isLastGoods="isLastGoods"
    :is-shein-club-gift="isSheinClubGiftItem"
    source="outer"
  />
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ShoppingBagItemV1 from './ShoppingBagItemV1'
import ShoppingBagItemV2 from './ShoppingBagItemV2'

export default {
  name: 'ShoppingBagItem',
  components: {
    ShoppingBagItemV1,
    ShoppingBagItemV2,
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    cartsCount: {
      type: [String, Number],
      default () {
        return 0
      }
    },
    fromType: {
      type: String,
      default: ''
    },
    showEditShoppingbag: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'canEditShoppingbag',
    ]),
    ...mapState('checkout', [
      'checkout',
      'defaultShipping',
    ]),
    isSheinClubGiftItem() {
      let isSheinClubGift = false
      this.item?.product?.product_promotion_info?.forEach(promotion => {
        // 根据promotion ID查促销接口获得promotion_type=4且promotion_logo_type如果为8就是会员赠品商品）
        // 且promotion_product_type === 1
        if (promotion.type_id == 4 && promotion.promotion_logo_type === 8 && promotion.promotion_product_type == '1') {
          isSheinClubGift = true
        }
      })
      return isSheinClubGift
    },
    outStockCarts() {
      if (this.checkout?.mall_caculate_info?.outStockCarts) {
        const _obj = {}
        this.checkout.mall_caculate_info.outStockCarts.forEach((item) => {
          _obj[item.id] = true
        })
        return _obj
      } else {
        return {}
      }
    },
    isOutStockGood() {
      return this.outStockCarts[this.item.id] || false
    },
    isSensitiveGood() {
      let isSensitive
      this.defaultShipping?.forEach(item => {
        if (item.mall_code === this.item?.mall_code) {
          const info = item.shipping_method.cmmdty_infos?.find(info => (
            info.item_no === this.item.id
          ))
          isSensitive = !!info
        }
      })
      return isSensitive
    },
    isEditShoppingbag() {
      return this.showEditShoppingbag && this.canEditShoppingbag
    },
  },
}
</script>
