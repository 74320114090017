<template>
  <div 
    class="shopping-bag__item-price"
    :class="priceClassName"
    :style="{ opacity: hidePrice ? 0 : 1 }"
  >
    <!-- 价格 -->
    <span
      v-if="!isClient"
      class="goods-price"
      :class="`j-goods-price__${item.id}`"
    >
      {{ getPriceByAbt }}
    </span>
    <div
      v-else
      class="goods-price"
      :style="{ maxWidth: '74px'}"
    >
      <SAdapterText
        :class="`j-goods-price__${item.id}`"
        min-size="10"
        :text="getPriceByAbt"
      />
    </div>
    <div
      v-if="isShowPriceDiscount"
      v-show="!isHideDiscount"
      class="goods-discount j-goods-discount"
      :class="[
        `j-goods-discount__${item.id}`,
        {
          'goods-discount-prime': isPremiumMember,
          'goods-discount-v2': discountLogoByAbt,
          'club-gift-discount': isSheinClubGift
        }
      ]"
      aria-hidden="true"
    >
      <span v-if="!GB_cssRight">-{{ item.product.unit_discount }}%</span>
      <span v-else>-%{{ item.product.unit_discount }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { AdapterText as SAdapterText } from '@shein/sui-mobile'
const { GB_cssRight } = gbCommonInfo

export default {
  name: 'ShoppingBagOptionsPrice',
  components: {
    SAdapterText,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isSheinClubGift: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hidePrice: true,
      isHideDiscount: false, // 是否因为宽度不够隐藏展示折扣标签
      isClient: false,
      GB_cssRight,
    }
  },
  computed: {
    ...mapState('checkout', [
      'price',
    ]),
    ...mapGetters('checkout', [
      'discountLogoByAbt',
      'isSuggested',
      'showArrivalPriceByAbt',
    ]),
    isPremiumMember () {
      return this.item?.promotionTypeIds?.includes(29)
    },
    priceClassName() {
      if(!!this.isSuggested) return ''
      if (this.isPremiumMember) return this.discountLogoByAbt ? 'goods-price-prime__v2' : 'goods-price-prime'
      if(this.isSheinClubGift) return 'club-gift-price'
      const isDiscount = this.item?.unitPrice?.amountWithSymbol != this.item?.product?.retailPrice?.amountWithSymbol
      if (isDiscount) return 'goods-price-red'
      return ''
    },
    getPriceByAbt() {
      let originPrice = this.item?.unitPrice?.amountWithSymbol
      if(!this.showArrivalPriceByAbt) return originPrice
      
      let actualPriceObj = this.price.cart_sub_infos?.find(item => item.cart_id == this.item.id) || {}
      const { single_sub_total } = actualPriceObj
      if(!single_sub_total) return originPrice
      return single_sub_total.amountWithSymbol
    },
    // 是否展示价格折扣，目前在运输方式大件物品通知抽屉中不展示
    isShowPriceDiscount() {
      return !this.isSuggested && this.item.product.unit_discount > 0 && this.source != 'largerInform'
    },
  },
  watch: {
    item: {
      handler() {
        this.$nextTick(() => this.calWidth())
      },
      deep: true,
    },
  },
  mounted() {
    this.isClient = true
    this.$nextTick(() => {
      this.calWidth()
    })
  },
  methods: {
    calWidth() {
      const MAX_WIDTH = 74
      const priceWidth = $(`.j-goods-price__${this.item.id}`)?.[0]?.offsetWidth || 0
      const discountWidth = $(`.j-goods-discount__${this.item.id}`)?.[0]?.offsetWidth || 0
      if(priceWidth + discountWidth > MAX_WIDTH) this.isHideDiscount = true
      this.hidePrice = false
    },
  },
}
</script>

<style lang="less" scoped>
.shopping-bag__item-price {
  width: 148/75rem;
  height: 30/75rem;
  text-align: center;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  &.goods-price-red {
    color: @sui_color_discount;
  }
  &.goods-price-prime {
    color: @sui_color_club_rosegold_dark2;
  }
  &.goods-price-prime__v2 {
    color: @sui_color_club_rosegold_dark1;
  }
  &.club-gift-price {
    color: @sui_color_club_rosegold_dark1;
  }

  .goods-price {
    .font-dpr(24px);
    font-weight: bold;
    display: inline-block;
  }

  .goods-discount {
    display: inline-block;
    border-radius: 2px;
    padding: 2/75rem 4/75rem;
    border: 1px solid @sui_color_discount;
    color: @sui_color_discount;
    .font-dpr(20px);
    .margin-l(4/75rem);
    &.goods-discount-prime {
      border: 1px solid @sui_color_club_rosegold_dark2;
      color: @sui_color_club_rosegold_dark2;
    }
  }
  .goods-discount-v2 {
    &.goods-discount-prime {
      border: 1px solid @sui_color_club_rosegold_dark1;
      color: @sui_color_club_rosegold_dark1;
    }
  }
  .club-gift-discount {
    border: 1px solid @sui_color_club_rosegold_dark1;
    color: @sui_color_club_rosegold_dark1;
  }
}
</style>
