<template>
  <li
    :class="['bag-item-effiency', 'shopping-bag-options__item-wrap', { opacityIdx6: index == 5 } ]"
  >
    <div class="shopping-bag-item__image">
      <img 
        v-if="fromType == 'shoppingMall'"
        class="i-img" 
        :class="{'fsp-element': index == 0}"
        :fsp-key="index == 0 ? `checkout_ShoppingBagItemV1_1_${baseCutImg(true)}` : null"
        :src="baseCutImg(true)" 
        fetchpriority="high"
      />
      <img
        v-else
        class="i-img"
        :src="baseCutImg()"
      />
      <img
        v-if="isSensitiveGood"
        class="product-sensitive"
        :src="`${PUBLIC_CDN}/pwa_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
      />
      <div
        class="product-num"
        :class="{'product-num-welfare': +(item.quantity >= 2)}"
      >
        <span>x{{ item.quantity }}</span>
      </div>
      <div 
        v-if="index == 4 && outDisplaySkuCount" 
        class="goodsli-out-stock goodsli-out-mask" 
      >
        <div class="goodsli-out-stock__content">
          <div
            class="out-sku-num"
          >
            {{ outDisplaySkuCountTips }}
          </div>
        </div>
      </div>
      <div 
        v-else-if="isOutStockGood" 
        class="goodsli-out-stock goodsli-out-mask" 
      >
        <div class="goodsli-out-stock__content">
          <i
            class="iconfont soldout-icon"
            :class="IS_RW ? 'icon-category-' : 'icon-SOLDOUT'"
          ></i>
          <span
            class="stocktip"
          >{{ language.SHEIN_KEY_PWA_14987 }}</span>
        </div>
      </div>
    </div>
    <div 
      v-if="chemicalsIds.includes(item.product.goods_sn)" 
      class="warning-tip img-inner" 
      @click.stop="showP65Warning(item.product.goods_sn)" 
    >
      <img :src="IMG_LINK['warning']" />
      <span>{{ language.SHEIN_KEY_PWA_20813 }}</span>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { transformImg } from '@shein/common-function'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'

const { IMG_LINK, PUBLIC_CDN } = gbCommonInfo

export default {
  name: 'ShoppingBagItem',
  props: {
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => {}
    },
    isOutStockGood: {
      type: Boolean,
      default: false
    },
    cartsCount: {
      type: [String, Number],
      default () {
        return 0
      }
    },
    fromType: {
      type: String,
      default: ''
    },
    isSensitiveGood: {
      type: Boolean,
      default: false
    },
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  data() {
    return {
      IMG_LINK,
      PUBLIC_CDN,
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'chemicalsIds',
      'customServiceImgDomains',
      'thirdPartyServiceImgDomains'
    ]),
    ...mapState('checkout', [
      'language', 
      'checkout', 
      'locals', 
    ]),
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    outDisplaySkuCount() {
      return +this.cartsCount
    },
    outDisplaySkuCountTips() {
      return this.outDisplaySkuCount >= 99 ? '99+' : `+${this.outDisplaySkuCount}`
    },
  },
  methods: {
    ...mapMutations('checkout', ['assignState']),
    transformImg,
    async showP65Warning (goods_sn) {
      let caculate_info = this.checkout?.mall_caculate_info || {}
      let { defaultP65TipsContent, chemicalsIdsEvt } = await import('public/src/pages/checkout/tools/p65util.js')
      let p65Text = await chemicalsIdsEvt(caculate_info, goods_sn)
      this.assignState({
        p65TipsShowText: p65Text || defaultP65TipsContent(this.language),
        p65TipsShow: true
      })
      sa('send', { activity_name: 'click_p65warning' })
    },
    baseCutImg(needReplace) {
      const url = getMainImageUrl({ 
        item: this.item, 
        IS_RW: this.IS_RW,
        customServiceImgDomains: this.customServiceImgDomains,
        thirdPartyServiceImgDomains: this.thirdPartyServiceImgDomains,
      })

      if (!this?.cutImg) {
        return needReplace ? url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp') : url 
      }
      return this.cutImg(url, 750, { cutTo: 240, max: 240 })
    },
  }
}
</script>

<style lang="less" scoped>
.opacityIdx6{
  opacity: 0;
}
.out-sku-num {
  .font-dpr(48px);
  font-weight: bold;
  color: #fff;
}

.bag-item-effiency {
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-bottom: none;
  padding: 0;
  .margin-r(8/75rem);
  margin-bottom: 0;
  width: 134/75rem;

  .shopping-bag-item__image {
    position: relative;
    width: 134/75rem;
    height: 134/75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .03);
    overflow: hidden;
    flex-shrink: 0;

    .i-img {
      width: 100%;
    }
  }

  .product-sensitive {
    width: 32/75rem;
    height: 32/75rem;
    position: absolute;
    top: 8/75rem;
    right: 8/75rem;
  }
  .product-num{
    position: absolute;
    width: .74rem;
    height: .74rem;
    line-height: .74rem;
    left: 0;
    right: 0;
    top: .947rem;
    margin: auto;
    border-radius: 999px;
    background: rgba(255,255,255,.8);
    text-align: center;
    .font-dpr(22px);
    font-weight: bold;
  }
  .goodsli-out-stock {
    position: absolute;
    background: rgba(0, 0, 0, .3);
    color: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    .goodsli-out-stock__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      text-align: center;
      width: 100%;
    }
  }
  .goodsli-out-mask {
    background: rgba(0, 0, 0, .6);
  }
}
.warning-tip {
  margin-top: 0.16rem;
	width: 134/75rem;
	background: rgba(255, 231, 231, 0.7);
	border: 1px solid rgba(255, 65, 28, 0.6);
	display: flex;
	align-items: center;
  padding-left: 0.053rem;
	text-align: center;
	font-size: 0;
  /* rw:begin */
  background: #FFF6F4;
	border: 1px solid #E34E60;
	img {
		height: 0.267rem;
		width: auto;
		margin-right: 1px;
		vertical-align: sub;
	}
	span {
		display: inline-block;
		line-height: 0.4rem;
		text-transform: uppercase;
		font-size: 0.533rem;
		font-weight: bold;
		transform: scale(0.5);
    transform-origin: left;
		color: #FF411C/* rw:#E34E60 */;
	}
}

.product-num-welfare {
  color: @sui_color_welfare;
}
</style>
